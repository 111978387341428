import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import posthogJS from "posthog-js";

function PosthogInit() {
  const project = import.meta.env?.VITE_POSTHOG_PROJECT;
  const host = import.meta.env?.VITE_POSTHOG_HOST;
  if (!project || !host) {
    console.warn("Posthog not initialized: project or host not found");
    return;
  }

  posthogJS.init(project as string, {
    api_host: host as string,
  });

  return posthogJS;
}

if (process.env.NODE_ENV !== "development") {
  const ph = PosthogInit();
  const integrations = [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
      enableInp: true,
    }),
    // Sentry.replayIntegration(),
  ];
  if (ph) {
    integrations.push(
      new ph.SentryIntegration(ph, "truecold", 4506898963365888),
    );
  }

  Sentry.init({
    dsn: "https://d2c9192c89b0d33169d016973f8d0826@o4506898959892480.ingest.us.sentry.io/4506898963365888",
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    integrations,
  });
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
